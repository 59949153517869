@font-face {
  font-family: "AvenirNextRegular";
  src: local("AvenirNextRegular"),
      url("./fonts/AvenirNextRegular.ttf") format("truetype");
  font-weight: normal;
}
body{
  font-family: 'AvenirNextRegular',Arial,sans-serif;
}
.main-content{
  margin-top: 75px;
}
.text-white{
  color: #f2f2f2;
}
.text-gray-silver{
  color: #c0c0c0 !important
}
.text-details{
  text-align: justify;
}
.text-details>br{
  margin-bottom: 10px;
}
.box-tab h4{
  margin-top: 10px;
}