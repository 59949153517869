.welcome-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .welcome-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .welcome-popup-content {
    position: relative;
  }
  .welcome-popup-content img{
    width: 500px;
    height: 500px;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
    outline: none;
  }
  
  .close-button span {
    font-size: 20px;
    display: block;
    line-height: 1;
  }