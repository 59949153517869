
.div-style{
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    background-size: cover;
    height: 500px;
}
.title-style{
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 50px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 800;
    font-size: 40px;
    color:#1ec2f3 !important;
    font-family: 'AvenirNextRegular',Arial,sans-serif;  
}
.details-style{
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 30px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 18px;
    color: #fff !important;    
    font-family: 'AvenirNextRegular',Arial,sans-serif;  
}
.slide-text-box{
    padding: 10%;
    line-height: 50px;    
}
.slide-button-style{
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    text-decoration: none;
    box-sizing: border-box;
    visibility: inherit;
    color: #fff;
    background-color: #002E5B;
    border-color: #002E5B;
    margin-top: 10px;
    font-family: 'AvenirNextRegular',Arial,sans-serif;  
    padding: 0 20px;
}
.react-slideshow-container .default-nav{
    height: 100px;
    width: 100px;
    background: rgba(0,0,0,0.10);
    opacity: 0.5;
}